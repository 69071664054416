import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import caseNames from "./caseNames";

class PortoflioPanel extends React.Component {
  //showLine = () => {
  //  if(this.props.user){
  //    return (<b>Get Portfolio updates delivered to your inbox! 📫</b>);
  //  }
  //}
  componentDidMount() {
    console.log(this.props.user);
    if (this.props.user!=="false"){
      this.props.fetchPortfolioCases(this.props.user);
    }
  }
  
  generateCases = (caselist) => {
    const numberofcases = caselist.length;
    let cases = [];
    for (let i = 0; i < numberofcases; i++) {
      const priceLength = caselist[i].prices.length
      cases.push(
        <li className="case" key={i}>
          <div className="property" id="qty">
            {caselist[i].qty}
          </div>
          <div className="property" id="name">
            {caselist[i].name}
          </div>
          <div className="property" id="prices">
            {caselist[i].prices[priceLength-1]}
          </div>
          <div className="property" id="value">
            {caselist[i].value}
          </div>
          <div className="property" id="move">
            {caselist[i].change}
          </div>
          <div className="property" id="delete" onClick={()=>this.props.deleteCase(i)}>
            <DeleteIcon style={{ fill: "grey" }} className="delete" />
          </div>
        </li>
      );
    }
    return cases;
  };

  generateEmailform = () => {
    if (this.props.user !== "false"){
      return(
        <div className="emailform">
          <Button type="submit" id="submit" onClick={this.props.updatePortfolio}>
          Submit
          </Button>{" "}
          {this.props.showAlert()}
          <div className="placeholder"></div>
        </div>
      );
    }else{
      return (
        <div className="emailform">
          {this.props.showAlert()}
          <p className="emailTitle">
          <b>Get Portfolio updates delivered to your inbox! <span role="img">📫</span></b>
          </p>
          <TextField
            className="email"
            type="email"
            id="outlined-basic"
            label="e-mail"
            variant="outlined"
            onChange={this.props.handleEmail}
          />
          <Button type="submit" id="submit" onClick={this.props.submitEmail}>
          Submit
          </Button>{" "}
          <div className="emailAlert">{this.props.showEmailAlert()}</div>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="portfolio">
        <div className="topBar">
          <div className="portfolioselector">your items <span role="img">📦 </span>:</div>
          <div className="currency"><span role="img">🏦</span> : <b>USD</b></div>
        </div>
        <ul>
          <li className="case">
            <div className="propertytop" id="qtytop">
              quantity
            </div>
            <div className="propertytop" id="nametop">
              case
            </div>
            <div className="propertytop" id="pricetop">
              price
            </div>
            <div className="propertytop" id="valuetop">
              value
            </div>
            <div className="propertytop" id="changetop">
              gain 
            </div>
          </li>
          <hr className="separator"></hr>
          <div className="inventory">{this.generateCases(this.props.cases)}</div>
        </ul>
        <form autoComplete="off">
          <div className="addcase">
            <TextField
              className="caseqty"
              id="outlined-basic"
              label="qty"
              onChange={this.props.handleQty}
              variant="outlined"
              sx={{ mr: 1, mt: 3 }}
            />
            <Autocomplete
              disablePortal
              id="casename"
              options={caseNames}
              onInputChange={this.props.handleCaseName}
              sx={{ width: 390, mt: 3 }}
              renderInput={(params) => <TextField {...params} label="case" />}
            />
            <div className="addcasebutton" onClick={this.props.handleAddCase}>
              +
            </div>
          </div>
        </form>

        <div className="emailform">
          <this.generateEmailform/>
        </div>
      </div>
    );
  }
}

export default PortoflioPanel;
/*<Button type="submit" id="submit" onClick={this.props.submitEmail}>
          Submit
          </Button>{" "}
          <div className="emailAlert">{this.props.showEmailAlert()}</div>

        <div className="portfolioselector">
          <DropdownButton id="dropdown-basic-button" title="portfolio">
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </DropdownButton>
        </div>
*/
